import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    // 重定向
    path: '/',
    redirect: '/date'
  }, {
    path: '/mine',
    name: 'mine',
    component: () => import('../views/MinePage/MinePage.vue'),
    meta: {
      showTabbar: true,
    }
  }, {
    path: '/details',
    name: 'midetailsne',
    component: () => import('../views/DatePage/components/UserDetails.vue'),
    meta: {
      showTabbar: false,
    }
  }, {
    path: '/date',
    name: 'date',
    component: () => import('../views/DatePage/DatePage.vue'),
    meta: {
      showTabbar: true,
    }
  }, {
    path: '/matchmaker',
    name: 'matchmaker',
    component: () => import('../views/MatchmakerPage/MatchmakerPage.vue'),
    meta: {
      showTabbar: true,
    }
  }, {
    path: '/matchmakerdetails',
    name: 'matchmakerdetails',
    component: () => import('../views/MatchmakerPage/components/MatchmakerDetails.vue'),
    meta: {
      showTabbar: false,
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
