<template>
  <div id="app">
    <router-view />
    <tab-bar></tab-bar>
  </div>
</template>
<script>
import TabBar from "@/components/LayOut.vue"
export default {
  name: "App",
  components: {
    TabBar,
  },
};
</script>
<style lang="less">
@import "./style/base.css";
</style>
