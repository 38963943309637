<template>
    <div class='LayOut'>
        <van-tabbar v-model="active" active-color="#111111" inactive-color="#CCCCCC" route
            v-show="$route.meta.showTabbar">
            <van-tabbar-item to="/date">
                <span>约会</span>
                <template #icon="props">
                    <img :src="props.active ? icon1.active : icon1.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/matchmaker">
                <span>红娘</span>
                <template #icon="props">
                    <img :src="props.active ? icon2.active : icon2.inactive" />
                </template>
            </van-tabbar-item>
            <van-tabbar-item to="/mine">
                <span>我的</span>
                <template #icon="props">
                    <img :src="props.active ? icon3.active : icon3.inactive" />
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        //这里存放数据
        return {
            active: 0,
            icon1: {
                active: require("@/assets/tab/icon_yuehui_hong.png"),
                inactive: require("@/assets/tab/icon_yuehui.png"),
            },
            icon2: {
                active: require("@/assets/tab/icon_paidui_hong.png"),
                inactive: require("@/assets/tab/icon_hongniang.png"),
            },
            icon3: {
                active: require("@/assets/tab/icon_wode_hong.png"),
                inactive: require("@/assets/tab/icon_wode.png"),
            },
        };
    },
}
</script>
<style>

</style>